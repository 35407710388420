import axios from 'axios';
import Cookies from 'js-cookie';

const createAxiosInstance = () => {

  const instance = axios.create({
    baseURL: `https://sgcertificados.com/api/v1`,
    timeout: 5000,
  });

  const token = Cookies.get('X-SRF-TOKEN');
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return instance;
};


export default createAxiosInstance;
