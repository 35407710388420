import {
    Alert,
    Box,
    CircularProgress,
    Typography,
    IconButton,
  } from '@mui/material';
  import { DataGrid } from '@mui/x-data-grid';
  import VisibilityIcon from '@mui/icons-material/Visibility';
  import EditIcon from '@mui/icons-material/Edit';
  import DeleteIcon from '@mui/icons-material/Delete';
  import createAxiosInstance2 from '../../utils/AxiosConfig2';
  import { useEffect, useState } from 'react';
  import { useNavigate } from 'react-router-dom';
  import Button from '@mui/material/Button';

  export const ListaGuia = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [appAlert, setAppAlert] = useState({
      message: '',
      errors: [],
    });
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      fetchItems();
    }, []);
  
    const fetchItems = async () => {
      setIsLoading(true);
      try {
        const { data } = await createAxiosInstance2().get('/menu-request');
        setItems(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setAppAlert({
          message:
            !error.response || error.response.status === 500
              ? 'Error del servidor, intentalo más tarde'
              : 'Error al obtener los datos',
          errors: error.response?.data?.message
            ? Array.isArray(error.response.data.message)
              ? error.response.data.message
              : [error.response.data.message]
            : [],
        });
      }
    };
  
    const handleView = (id) => {
      // Manejar la acción de ver
      navigate(`/Guiaview/${id}`);
      console.log(`Ver item con id: ${id}`);
    };
  
    const handleEdit = (id) => {
        navigate(`/GuiaEdit/${id}`);
        //navigate(`/GuiaEdit`);
    };
  
    const handleDelete = async (id) => {
        // Manejar la acción de eliminar
        try {
          await createAxiosInstance2().delete(`/menu-request/${id}`);
          setItems((prevItems) => prevItems.filter((item) => item.id !== id));
          console.log(`Eliminar item con id: ${id}`);
        } catch (error) {
          setAppAlert({
            message:
              !error.response || error.response.status === 500
                ? 'Error del servidor, intentalo más tarde'
                : 'Error al eliminar el item',
            errors: error.response?.data?.message
              ? Array.isArray(error.response.data.message)
                ? error.response.data.message
                : [error.response.data.message]
              : [],
          });
        }
      };
  
    const columns = [
      { field: 'name', headerName: 'Nombre sección', flex: 1 },
      { field: 'description', headerName: 'Descripción sección', flex: 2 },
      {
        field: 'actions',
        headerName: 'Acciones',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <IconButton onClick={() => handleView(params.row.id)} color="primary">
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={() => handleEdit(params.row.id)} color="secondary">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(params.row.id)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ];
  
    return (
      <Box>
        <br />
        <Typography variant="h4" component="h2" sx={{ mb: 4 }}>
          Administrador de trámites y solicitudes
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 4 }}
          onClick={() => navigate('/guia')}
        >
          Crear secciones
        </Button>
        {appAlert.message && (
          <Alert severity="error" sx={{ mb: 3, textAlign: 'left' }}>
            {appAlert.message}
            {appAlert.errors.length > 0 && (
              <Box>
                {appAlert.errors.map((error, index) => (
                  <Box key={index}>
                    {error}
                  </Box>
                ))}
              </Box>
            )}
          </Alert>
        )}
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ height: 'auto', width: '100%' }}>
            <DataGrid
              rows={items}
              columns={columns}
              disableSelectionOnClick
              autoHeight
            />
          </Box>
        )}
      </Box>
    );
  };
  