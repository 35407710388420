import BusinessIcon from '@mui/icons-material/Business';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import createAxiosInstance from '../../utils/AxiosConfig';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Bogota');

export const Dashboard = () => {
  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appAlert, setAppAlert] = useState({
    message: '',
    errors: [],
  });
  const [selectedYear, setSelectedYear] = useState(dayjs());
  const [campusesData, setCampusesData] = useState([]);
  const [facultiesData, setFacultiesData] = useState([]);
  const [programsData, setProgramsData] = useState([]);
  const [downloadsData, setDownloadsData] = useState([]);
  const [barChartWeeklyActivity, setWeeklyActivity] = useState([]);
  const [barChartAnnualActivity, setBarChartAnnualActivity] = useState([]);
  const [colors, setColors] = useState([]);

  // filter
  const [term, setTerm] = useState('');
  const [searchCriteria, setSearchCriteria] = useState('');
  const [searchCriteriaData] = useState([
    {
      value: 'campus',
      label: 'Sede',
    },
    {
      value: 'faculty',
      label: 'Facultad',
    },
    {
      value: 'title',
      label: 'Programa académico',
    },
  ]);

  useEffect(() => {
    handleSetColors();
    fetchRequests();
  }, []);

  const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleSetColors = () => {
    const colorsData = [];
    for (let i = 0; i < 100; i++) {
      colorsData.push(getRandomHexColor());
    }
    setColors(colorsData);
  };

  const disableFutureYears = (date) => {
    return dayjs(date).year() > dayjs().year();
  };

  const buildDownloadsData = (data) => {
    const downloads = [];
    data.forEach((request) => {
      request.downloads.forEach((download) => {
        if (download.certificate) {
          downloads.push({
            campus: download.certificate.campus_name,
            faculty: download.certificate.faculty_name,
            program: download.certificate.title,
            download_date: download.download_date,
          });
        }
      });
    });
    return downloads;
  };

  const getUniqueData = (results, field) => {
    const uniqueData = new Set();
    results.forEach((item) => uniqueData.add(item[field]));
    const dataList = Array.from(uniqueData).map((item) => {
      return item;
    });
    return dataList;
  };

  const buildReport = (downloads, field) => {
    const uniqueData = getUniqueData(downloads, field);
    const data = uniqueData.map((item) => {
      return {
        name: item,
        value: downloads.filter((download) => download[field] === item).length,
      };
    });
    return data;
  };

  // data para el diagrama de torta sobre sedes
  const handleSetCampusesData = (downloads) => {
    const reportData = buildReport(downloads, 'campus');
    setCampusesData(reportData);
  };

  // data para el diagrama de torta sobre facultades
  const handleSetFacultiesData = (downloads) => {
    const reportData = buildReport(downloads, 'faculty');
    setFacultiesData(reportData);
  };

  // data para el diagrama de torta sobre programas académicos
  const handleSetProgramsData = (downloads) => {
    const reportData = buildReport(downloads, 'program');
    setProgramsData(reportData);
  };

  const getWeekDays = (date) => {
    const startOfWeek = dayjs.utc(date).startOf('isoWeek');
    const endOfWeek = dayjs.utc(date).endOf('isoWeek');

    const days = [];
    let currentDay = startOfWeek;

    while (currentDay <= endOfWeek) {
      days.push(currentDay.format('YYYY-MM-DD'));
      currentDay = currentDay.add(1, 'day');
    }

    return days;
  };

  const quantityDownloadsPerDay = (downloads, week) => {
    const count = downloads.filter((download) => {
      const downloadDataFormat = dayjs
        .utc(download.download_date)
        .format('YYYY-MM-DD');
      return downloadDataFormat === week;
    }).length;
    return count;
  };

  // data para mostrar la actividad semanas de descargas
  const handleSetWeeklyActivity = (downloads) => {
    const today = dayjs().format('YYYY-MM-DD');
    const weeks = getWeekDays(today);

    const buildBarchartData = [
      {
        name: 'Lun',
        uv: 1000,
        pv: quantityDownloadsPerDay(downloads, weeks[0]),
        amt: 2400,
      },
      {
        name: 'Mar',
        uv: 3000,
        pv: quantityDownloadsPerDay(downloads, weeks[1]),
        amt: 2210,
      },
      {
        name: 'Mier',
        uv: 2000,
        pv: quantityDownloadsPerDay(downloads, weeks[2]),
        amt: 2290,
      },
      {
        name: 'Juev',
        uv: 2780,
        pv: quantityDownloadsPerDay(downloads, weeks[3]),
        amt: 2000,
      },
      {
        name: 'Vier',
        uv: 1890,
        pv: quantityDownloadsPerDay(downloads, weeks[4]),
        amt: 2181,
      },
      {
        name: 'Saba',
        uv: 2390,
        pv: quantityDownloadsPerDay(downloads, weeks[5]),
        amt: 2500,
      },
      {
        name: 'Dom',
        uv: 3490,
        pv: quantityDownloadsPerDay(downloads, weeks[6]),
        amt: 2100,
      },
    ];
    setWeeklyActivity(buildBarchartData);
  };

  const quantityDownloadsPerMonthAndYear = (downloads, year, month) => {
    const count = downloads.filter((download) => {
      const downloadMonthFormat = dayjs
        .utc(download.download_date)
        .format('MM');
      const downloadYearFormat = dayjs
        .utc(download.download_date)
        .format('YYYY');
      return downloadMonthFormat === month && downloadYearFormat === year;
    }).length;
    return count;
  };

  // data para mostrar la actividad anual de descargar por mes
  const handleSetBarChartAnnualActivity = (downloads, year) => {
    const yearFormat = year.format('YYYY');

    const buildBarchartData = [
      {
        name: 'En.',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '01'),
      },
      {
        name: 'Feb.',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '02'),
      },
      {
        name: 'Mzo.',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '03'),
      },
      {
        name: 'Abr',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '04'),
      },
      {
        name: 'My',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '05'),
      },
      {
        name: 'Jun',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '06'),
      },
      {
        name: 'Jul',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '07'),
      },
      {
        name: 'Agt',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '08'),
      },
      {
        name: 'Sept',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '09'),
      },
      {
        name: 'Oct',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '10'),
      },
      {
        name: 'Nov',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '11'),
      },
      {
        name: 'Dic',
        pv: quantityDownloadsPerMonthAndYear(downloads, yearFormat, '12'),
      },
    ];
    setBarChartAnnualActivity(buildBarchartData);
  };

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      const { data } = await createAxiosInstance().get(`/requests`);
      setResults(data);
      setResponse(data);

      const downloads = buildDownloadsData(data);
      setDownloadsData(downloads);

      handleSetCampusesData(downloads);
      handleSetFacultiesData(downloads);
      handleSetProgramsData(downloads);

      handleSetWeeklyActivity(downloads);
      handleSetBarChartAnnualActivity(downloads, selectedYear);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al obtener los datos',
        errors:
          error.response && error.response.data && error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? error.response.data.message
              : [error.response.data.message]
            : [],
      });
    }
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        style={{ fontSize: '10px' }}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {/* <tspan x={x} dy='0'>{data[index].name}</tspan> */}
        <tspan x={x} dy='1.2em'>{`${(percent * 100).toFixed(0)}%`}</tspan>
      </text>
    );
  };

  const handleSearchTerm = (e) => {
    e.preventDefault();
    handleFilteredRequests(searchCriteria);
  };

  const handleSearchCriteria = (e) => {
    setSearchCriteria(e.target.value);
    handleFilteredRequests(e.target.value);
  };

  const handleFilteredRequests = (searchCriteria) => {
    const data = structuredClone(response);
    const filteredRequests = data.filter((request) => {
      if (term) {
        const filteredDownloads = request.downloads.filter((download) =>
          download.certificate[searchCriteria]
            .toLowerCase()
            .includes(term.toLowerCase())
        );
        request.downloads = filteredDownloads;
        return filteredDownloads.length > 0;
      }
      return true;
    });

    const downloads = buildDownloadsData(filteredRequests);
    setDownloadsData(downloads);

    handleSetCampusesData(downloads);
    handleSetFacultiesData(downloads);
    handleSetProgramsData(downloads);

    handleSetWeeklyActivity(downloads);
    handleSetBarChartAnnualActivity(downloads, selectedYear);
    setResults(filteredRequests);
  };

  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='intro'>{`${label} ${payload[0].value} descargas`}</p>
        </div>
      );
    }
    return null;
  };

  const handleSetSelectedYear = (value) => {
    setSelectedYear(value);
    const downloads = buildDownloadsData(results);
    handleSetBarChartAnnualActivity(downloads, value);
  };

  return (
    <Box>
      <Typography variant='h4' component='h2' sx={{ mb: 4 }}>
        Reporte Gráfico
      </Typography>
      {appAlert && appAlert.message && (
        <Alert severity='error' sx={{ mb: 3, textAlign: 'left' }}>
          {appAlert.message}
          {appAlert.errors.length > 0 && (
            <List>
              {appAlert.errors.map((error, index) => (
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemText primary={error} />
                </ListItem>
              ))}
            </List>
          )}
        </Alert>
      )}
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            component='form'
            sx={{
              display: 'flex',
              mt: 3,
              mb: 3,
            }}
            onSubmit={handleSearchTerm}
            noValidate
            autoComplete='off'
          >
            <TextField
              id='subjectSearchCriteria'
              select
              label='Criterios de búsqueda'
              defaultValue=''
              helperText=''
              sx={{ minWidth: '210px' }}
              onChange={(event) => {
                handleSearchCriteria(event);
              }}
            >
              {searchCriteriaData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              id='subjectFilterTerm'
              label='Buscar...'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Filtrar asignaturas'
                      type='submit'
                      edge='end'
                      sx={{ fontSize: '20px' }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setTerm(event.target.value)}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} xl={6}>
              <Typography
                variant='h6'
                component='h4'
                sx={{ mb: 2, textAlign: 'center' }}
              >
                Actividad Semanal
              </Typography>
              <Grid
                container
                spacing={1}
                justifyContent='center'
                sx={{ mb: 2 }}
              >
                <Grid item>
                  <Card sx={{ height: '100%' }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <PeopleIcon
                        size='large'
                        sx={{ display: 'inline-flex' }}
                      ></PeopleIcon>
                      <Typography variant='h5'>{results.length}</Typography>
                      <Typography variant='body2'>Usuarios</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card sx={{ height: '100%' }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <AssignmentTurnedInIcon
                        size='large'
                        sx={{ display: 'inline-flex' }}
                      ></AssignmentTurnedInIcon>
                      <Typography variant='h5'>
                        {downloadsData.length}
                      </Typography>
                      <Typography variant='body2'>
                        Constancias verificadas
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Card>
                <CardContent>
                  <Box sx={{ height: '284px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={300}
                        data={barChartWeeklyActivity}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey='name'
                          label={{
                            value: 'Días',
                            position: 'insideBottom',
                            offset: -5,
                            textAnchor: 'middle',
                          }}
                          scale='point'
                          padding={{ left: 10, right: 10 }}
                        />
                        <YAxis
                          label={{
                            value: 'Cantidad de descargas',
                            angle: -90,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                            dy: 80,
                            dx: 10,
                          }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Legend verticalAlign='top' height={36}/> */}
                        <CartesianGrid strokeDasharray='3 3' />
                        <Bar
                          dataKey='pv'
                          fill={colors[colors.length]}
                          background={{ fill: '#eee' }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              xl={6}
              display='flex'
              flexDirection='column'
            >
              <Typography
                variant='h6'
                component='h4'
                sx={{ mb: 2, textAlign: 'center' }}
              >
                Actividad anual
              </Typography>
              <Grid
                container
                spacing={1}
                justifyContent='center'
                sx={{ mb: 2 }}
              >
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year']}
                      label='Año'
                      value={selectedYear}
                      onChange={(newValue) => handleSetSelectedYear(newValue)}
                      shouldDisableYear={disableFutureYears}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Card sx={{ marginTop: 'auto' }}>
                <CardContent>
                  <Box sx={{ height: '284px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={300}
                        data={barChartAnnualActivity}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey='name'
                          label={{
                            value: 'Meses',
                            position: 'insideBottom',
                            offset: -5,
                            textAnchor: 'middle',
                          }}
                          scale='point'
                          padding={{ left: 10, right: 10 }}
                        />
                        <YAxis
                          label={{
                            value: 'Cantidad de descargas',
                            angle: -90,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                            dy: 80,
                            dx: 10,
                          }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Legend /> */}
                        <CartesianGrid strokeDasharray='3 3' />
                        <Bar
                          dataKey='pv'
                          fill={colors[colors.length - 1]}
                          background={{ fill: '#eee' }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <Typography variant='h6' component='h4' sx={{ mb: 2 }}>
                Sedes
              </Typography>
              <Card>
                <CardContent>
                  <Box sx={{ height: '284px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={campusesData}
                          cx='50%'
                          cy='50%'
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill='#8884d8'
                          dataKey='value'
                        >
                          {campusesData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </Pie>
                        {/* <Tooltip /> */}
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <Typography variant='h6' component='h4' sx={{ mb: 2 }}>
                Facultades
              </Typography>
              <Card>
                <CardContent>
                  <Box sx={{ height: '284px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={facultiesData}
                          cx='50%'
                          cy='50%'
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill='#8884d8'
                          dataKey='value'
                        >
                          {facultiesData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </Pie>
                        {/* <Tooltip /> */}
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <Typography variant='h6' component='h4' sx={{ mb: 2 }}>
                Programas académicos
              </Typography>
              <Card>
                <CardContent>
                  <Box sx={{ height: '284px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={programsData}
                          cx='50%'
                          cy='50%'
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill='#8884d8'
                          dataKey='value'
                        >
                          {programsData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </Pie>
                        {/* <Tooltip /> */}
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <Typography variant='h6' component='h4' sx={{ mb: 2 }}>
                Solicitantes
              </Typography>
              <Card>
                <CardContent>
                  <List
                    dense={true}
                    sx={{
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: 284,
                      '& ul': { padding: 0 },
                    }}
                  >
                    {results.map((request, index) => (
                      <ListItem
                        key={request.id}
                        secondaryAction={request.downloads.length}
                        sx={{
                          pl: 0,
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <BusinessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={request.nit}
                          secondary={request.organization}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
