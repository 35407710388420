import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import createAxiosInstance from '../../utils/AxiosConfig';
import { useAuth } from '../../providers/AuthProvider';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const Profile = () => {
  const auth = useAuth();
  const [isLoadingToSendImage, setIsLoadingToSendImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appAlert, setAppAlert] = useState({
    message: '',
    type: 'error',
    errors: [],
  });
  const [image, setImage] = useState('');
  const [file, setFile] = useState(null);
  const [asignatureData, setAsignatureData] = useState(null);
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    fetchUserAsignature();
  }, []);

  const initAppAlert = () => {
    setAppAlert({
      message: '',
      type: 'error',
      errors: [],
    });
  };

  const fetchUserAsignature = async () => {
    setIsLoading(true);
    try {
      const { data } = await createAxiosInstance().get(
        `/signatures/${auth.getUser().id}`
      );
      if (data && data.length) {
        setCurrentImage(data[0].signature);
        setAsignatureData(data[0]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al recuperar la firma',
        errors:
          error.response && error.response.data && error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? error.response.data.message
              : [error.response.data.message]
            : [],
      });
    }
  };

  const handleImageChange = (event) => {
    initAppAlert();
    const file = event.target.files[0];
    setFile(file);
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
    if (file && file.size <= maxFileSize && file.type === 'image/png') {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      setAppAlert({
        message: '',
        type: '',
        errors: [],
      });
    } else {
      setAppAlert({
        message: 'Solo se permite la imágen en formato png y un peso máximo de 1MB',
        type: 'warning',
        errors: [],
      });
    }
  };

  const handleSendImage = async () => {
    initAppAlert();
    setIsLoadingToSendImage(true);
    try {
      const request = {
        user: auth.getUser().id,
        signature: image,
      };
      const formData = new FormData();
      formData.append('signature', file, file.name);
      formData.append('user', request.user);
      const { data } = await createAxiosInstance().post(`/signatures`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (data) {
        setAppAlert({
          message: 'Imagen guardada correctamente',
          errors: [],
          type: 'success',
        });
      }
      setIsLoadingToSendImage(false);
    } catch (error) {
      setIsLoadingToSendImage(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al subir la firma',
        errors:
          error.response && error.response.data && error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? error.response.data.message
              : [error.response.data.message]
            : [],
      });
    }
  };

  const handleUpdateImage = async () => {
    initAppAlert();
    setIsLoadingToSendImage(true);
    try {
      const request = {
        user: auth.getUser().id,
        signature: image,
      };
      const formData = new FormData();
      formData.append('signature', file, file.name);
      formData.append('user', request.user);
      const { data } = await createAxiosInstance().patch(
        `/signatures/${asignatureData.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (data) {
        setAppAlert({
          message: 'Imagen actualizada correctamente',
          errors: [],
          type: 'success',
        });
      }
      setIsLoadingToSendImage(false);
    } catch (error) {
      setIsLoadingToSendImage(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al subir la firma',
        errors:
          error.response && error.response.data && error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? error.response.data.message
              : [error.response.data.message]
            : [],
      });
    }
  };

  return (
    <Box>
      <Typography variant='h4' component='h2' sx={{ mb: 4 }}>
        Perfil
      </Typography>
      {appAlert && appAlert.message && (
        <Alert
          severity={appAlert.type ?? 'error'}
          sx={{ mb: 3, textAlign: 'left' }}
        >
          {appAlert.message}
          {appAlert.errors.length > 0 && (
            <List>
              {appAlert.errors.map((error, index) => (
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemText primary={error} />
                </ListItem>
              ))}
            </List>
          )}
        </Alert>
      )}
      <Grid container spacing={2} sx={{ mb: 2 }} justifyContent='center'>
        <Grid item xs={12} sm={6} lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component={image ? 'img' : 'div'}
                alt='Imagén de firma'
                sx={{ height: 140, backgroundColor: '#cecece' }}
                image={currentImage && !image ? currentImage : image}
              ></CardMedia>
              {isLoading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Box>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                Firma
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                Debe incluir la firma para los documentos solicitados
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                onChange={handleImageChange}
                startIcon={<CloudUploadIcon />}
              >
                Cargar
                <VisuallyHiddenInput type='file' />
              </Button>
              {currentImage ? (
                <Button
                  variant='contained'
                  color='success'
                  disabled={!image || isLoadingToSendImage}
                  onClick={handleUpdateImage}
                >
                  {isLoadingToSendImage ? 'Procesando...' : 'Actualizar'}
                </Button>
              ) : (
                <Button
                  variant='contained'
                  color='success'
                  disabled={!image || isLoadingToSendImage}
                  onClick={handleSendImage}
                >
                  {isLoadingToSendImage ? 'Procesando...' : 'Aceptar'}
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
