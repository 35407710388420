import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { localeText } from '../../../config/config';
import ClearIcon from '@mui/icons-material/Clear';
import 'dayjs/locale/es'; // Importar la localización en español

dayjs.locale('es');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Bogota');

export const ViewCertificates = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let data = state && state.data;

  const [selectedDate, setSelectedDate] = useState(null);
  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState({message: ''});
  const [results, setResults] = useState([]);

  const fetchFaculties = async (results) => {
    const uniqueItems = new Set();
    results.forEach((item) => uniqueItems.add(item.faculty_name));
    const faculties = Array.from(uniqueItems).map((faculty) => {
      return faculty;
    });
    setFaculties(faculties);
  };

  useEffect(() => {
    if (!data) {
      navigate('/buscador-certificados');
    } else {
      setResults(data);
      fetchFaculties(data);
    }
  }, []);

  const getFormatDate = (date) => {
    const formatDate = dayjs.utc(date).format('YYYY-MM-DD');
    return formatDate;
  }

  const handleSearchTerm = (event) => {
    event.preventDefault();
    const date = getFormatDate(selectedDate);
    if (faculty || selectedDate) {
      const newResults = data.filter(d => {
        const isValid = (d.faculty_name === faculty || getFormatDate(d.final_date) === date);
        return isValid;
      });
      setResults(newResults);
    } else {
      setResults(data);
    }
  };

  const handleChangeFaculty = (value) => {
    setFaculty(value);
  };

  const handleSelectionModelChange = (selectedOptions) => {
    const selectedObjects = selectedOptions.map(id => data.find(d => d.id === id));
    setSelectedRows(selectedObjects);
  };

  const handleDownload = () => {
    if (selectedRows.length) {
      navigate(`/solicitar-datos/`, {
        replace: false, // en true permite no volver a esta pagina
        state: {
          data: selectedRows,
        },
      });
      return;
    }
    setError({
      message: 'Porfavor seleccione algun registro'
    })
  };

  const handlePageBack = () => {
    navigate(`/buscador-certificados/`, {
      replace: false,
    });
  }

  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'identification_number',
      headerName: 'Número de documento',
      minWidth: 230,
      flex: 1,
    },
    {
      field: 'fullName',
      headerName: 'Nombre',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const value = `${params.row.first_name} ${params.row.middle_name} ${params.row.last_name}`;
        return <span>{value}</span>;
      },
    },
    {
      field: 'faculty_name',
      headerName: 'Facultad',
      minWidth: 230,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Título',
      minWidth: 200,
      flex: 1,
    },
  ];

  const currentYear = dayjs().year();
  const minYear = currentYear - 3;
  const maxYear = currentYear - 1;

  return (
    <Box sx={{ pt: 6, pb: 6 }}>
      <Typography variant='h4' component='h2'>
        Consulta
      </Typography>
      <Box
        component='form'
        sx={{
          display: { md: 'flex' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mt: 3,
          mb: 3,
          '.MuiBox-root': {
            background: { md: '#F9F9F9' },
          },
        }}
        onSubmit={handleSearchTerm}
        noValidate
        autoComplete='off'
      >
        <Box
          sx={{
            display: 'flex',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            alignItems: 'center',
            flex: 1,
            border: { md: '1px solid #E6E6E6' },
          }}
        >
          <Typography variant='body2' sx={{ p: 2 }}>
            Filtrar por
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: { xs: '12px', md: '0' },
            flexDirection: { xs: 'column', sm: 'row' },
            // flex: '0 1 50%',
            '.MuiFormControl-root, .MuiAutocomplete-root': {
              width: { sm: '250px' },
            },
          }}
        >
          <Box position='relative'>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
              <DatePicker
                label='Fecha'
                minDate={dayjs(`${minYear}-01-01`)}
                maxDate={dayjs(`${maxYear}-12-31`)}
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
              />
              { selectedDate &&
              <IconButton aria-label="Resetear fecha" onClick={() => setSelectedDate(null)} size='small'
                sx={{ position: 'absolute', top: '50%', right: '38px', transform: 'translateY(-50%)' }}>
                <ClearIcon />
              </IconButton>
              }
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: 'flex', gap: { xs: '12px', md: '0' } }}>
            <Autocomplete
              disablePortal
              id='faculties'
              name='faculties'
              options={faculties}
              value={faculty ? faculty : null}
              getOptionLabel={(option) => option}
              getOptionKey={(option) => option}
              sx={{ width: '100%' }}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, newInputValue) => {
                handleChangeFaculty(newInputValue);
              }}
              renderOption={(props, option) => (
                <Box component='li' {...props} key={option}>
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='large'
                  label='Facultad'
                  helperText=''
                />
              )}
            />

            <Button
              type='submit'
              size='large'
              variant='contained'
              color='primary'
              aria-label='Buscar'
            >
              <SearchIcon />
            </Button>
          </Box>
        </Box>
      </Box>

      {error && error.message && (
      <Box>
        <Alert severity='warning' sx={{ mb: 3 }}>
          {error.message}
        </Alert>
      </Box>
      )}

      <Box sx={{ height: 400, width: '100%', mb: 3 }}>
        <DataGrid
          columnVisibilityModel={{ id: false }}
          rows={results}
          columns={columns}
          slotProps={{
            pagination: {
              labelRowsPerPage: 'Filas por página',
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
          checkboxSelection
          disableColumnMenu
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) =>
            handleSelectionModelChange(newSelection)
          }
          localeText={localeText}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
        <Button variant='contained' onClick={() => handlePageBack()}>
          Regresar
        </Button>
        <Button variant='contained' onClick={() => handleDownload()}>
          Descargar
        </Button>
      </Box>
    </Box>
  );
};
