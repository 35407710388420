import {
  Box,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { LoginForm } from "./LoginForm";

export const Login = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Box sx={{ pt: 6, pb: 6, width: { sm: 458, xs: '100%' } }}>
        <Card variant="outlined">
          <CardContent>
            <LoginForm />
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};
