import { Box, Container, Link } from '@mui/material';
import { Header } from './header';
import { Footer } from './footer';
import PersonIcon from '@mui/icons-material/Person';

export const PublicLayout = ( { content } ) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='xl'>
          {content}
          <Box textAlign='right' sx={{ p: 3 }} >
            <Link href="https://gestiontic.unal.edu.co/USDKV8/#!/login/ENLACE"
              target='_blank'
              underline="hover"
              style={{
                display: 'inline-flex',
                gap: '4px',
                color: '#000',
                fontWeight: 700,
                }}>PQRS<PersonIcon></PersonIcon></Link>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};
