import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { localeText } from '../../config/config';
import { DataGrid } from '@mui/x-data-grid';
import createAxiosInstance from '../../utils/AxiosConfig';

export const UserManage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingToCreateUser, setIsLoadingToCreateUser] = useState(false);
  const [isLoadingToInactivateUser, setIsLoadingToInactivateUser] =
    useState(false);
  const [appAlert, setAppAlert] = useState({
    message: '',
    errors: [],
  });
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [facultiesOptions, setFacultiesOptions] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [response, setResponse] = useState([]);
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      campus: null,
      faculty: null,
      lastName: '',
    },
  });

  useEffect(() => {
    fetchCampuses();
    fetchFacultySecretaries();
  }, []);

  const handleFacultiesOptions = (campuses) => {
    const options = [];
    campuses.forEach((c) => {
      c.faculties?.forEach((f) => {
        options.push(f);
      });
    });

    const uniqueItems = new Set();
    options.forEach((item) => uniqueItems.add(item.name));

    const faculties = Array.from(uniqueItems).map((faculty) => {
      return faculty;
    });
    setFacultiesOptions(faculties);
  };

  const fetchCampuses = async () => {
    setIsLoading(true);
    try {
      const { data } = await createAxiosInstance().get(`/sedes`);
      setCampuses(data);
      handleFacultiesOptions(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al obtener las sedes',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ?  Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const fetchFacultySecretaries = async () => {
    setIsLoading(true);
    try {
      const { data } = await createAxiosInstance().get(
        `/admins/getAllUsersFacultySecretaries`
      );
      setResponse(data);
      setResults(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al obtener los secretarios de facultad',
          errors:
            error.response &&
            error.response.data &&
            error.response.data.message
              ?  Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
              : [],
      });
    }
  };

  const emailRules = {
    required: 'El email es requerido',
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: 'El formato del email no es válido',
    },
  };

  const nameRules = {
    required: 'El nombre es requerido',
  };

  const lastNameRules = {
    required: 'El apellido es requerido',
  };


  const onSubmit = async (formData) => {
    setIsLoadingToCreateUser(true);
    const request = {
      name: formData.name,
      lastname: formData.lastName,
      email: formData.email,
      role: 'faculty-secretary',
      sede: formData.campus?.id,
      faculty: formData.faculty?.id,
    };

    try {
      await createAxiosInstance().post(
        `/auth/registerFacultySecretary`,
        request
      );
      setIsLoadingToCreateUser(false);
      reset();
      fetchFacultySecretaries();
      setAppAlert({message: '', errors: []});
    } catch (error) {
      setIsLoadingToCreateUser(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al crear el secretario de facultad',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ?  Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const handleOnChangeCampus = (campus) => {
    const faculties = campus
      ? campuses.find((c) => c.id === campus.id).faculties
      : [];
    setFaculties(faculties);
  };

  const handleSearchTerm = (event) => {
    event.preventDefault();
    console.log('SelectedFaculty', selectedFaculty);
    const resultsByFaculty = response.filter((r) =>
      r._faculty.name.toLowerCase().includes(selectedFaculty?.toLowerCase())
    );
    setResults(resultsByFaculty);
  };

  const handleChangeFaculty = (value) => {
    if (!value) {
      setResults(response);
    }
    setSelectedFaculty(value);
  };

  const handleStatusClick = (row) => {
    setOpen(true);
    setSelectedUser(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogConfirm = async () => {
    if (!selectedUser) return;
    setIsLoadingToInactivateUser(true);
    try {
      const { data } = await createAxiosInstance().patch(
        `/admins/inactiveUser/${selectedUser.id}`
      );
      if (data.data) {
        handleClose();
        fetchFacultySecretaries();
      }
      setIsLoadingToInactivateUser(false);
    } catch (error) {
      setIsLoadingToInactivateUser(false);
      setAppAlert({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error para inactivar el usuario',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ?  Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'email',
      headerName: 'Correo electrónico',
      minWidth: 230,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nombre del secretario',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'campus',
      headerName: 'Sede',
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return params.row._sede.name;
      },
    },
    {
      field: 'faculty',
      headerName: 'Facultad',
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {        
        return <span title={params.row._faculty.name}>{params.row._faculty.name}</span>;
      },
    },
    {
      field: 'state',
      headerName: 'Estado',
      description: '',
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: (params) =>
        params.row.state ? (
          <Button
            onClick={() => handleStatusClick(params.row)}
            variant='contained'
            color='error'
          >
            Inactivar
          </Button>
        ) : (
          <Typography
            variant='body2'
            component='span'
            sx={{ color: 'success.main' }}
          >
            Activo
          </Typography>
        ),
    },
  ];

  return (
    <Box>
      <Typography variant='h4' component='h2' sx={{ mb: 4 }}>
        Gestión de usuarios
      </Typography>
      {appAlert && appAlert.message && (
        <Alert severity='error' sx={{ mb: 3, textAlign: 'left' }}>
          {appAlert.message}
          {appAlert.errors.length > 0 && (
            <List>
              {appAlert.errors.map((error, index) => (
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemText primary={error} />
                </ListItem>
              ))}
            </List>
          )}
        </Alert>
      )}
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { width: '100%' },
          mb: 4,
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <Controller
              name='email'
              control={control}
              rules={emailRules}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Correo electrónico'
                  variant='outlined'
                  margin='normal'
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Controller
              name='name'
              control={control}
              rules={nameRules}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Nombre'
                  variant='outlined'
                  margin='normal'
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Controller
              name='lastName'
              control={control}
              rules={lastNameRules}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Apellido(s)'
                  variant='outlined'
                  margin='normal'
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Controller
              name='campus'
              control={control}
              rules={{ required: 'La sede es requerida' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={campuses}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    field.onChange(value);
                    handleOnChangeCampus(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Sede'
                      required
                      error={!!errors.campus}
                      helperText={errors.campus ? errors.campus.message : ''}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Controller
              name='faculty'
              control={control}
              rules={{ required: 'La facultad es requerida' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={faculties}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    field.onChange(value);
                    // handleOnChangeFaculty(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Facultad'
                      required
                      error={!!errors.faculty}
                      helperText={errors.faculty ? errors.faculty.message : ''}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={3}
            sx={{ textAlign: { xs: 'center', sm: 'left' } }}
          >
            <Button
              type='submit'
              variant='contained'
              size='large'
              disabled={isLoadingToCreateUser}
              color='primary'
              sx={{ minHeight: '56px' }}
            >
              {isLoadingToCreateUser ? 'Cargando...' : 'Crear'}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            component='form'
            sx={{
              display: { md: 'flex' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              mt: 3,
              mb: 3,
              '.MuiBox-root': {
                background: { md: '#F9F9F9' },
              },
            }}
            onSubmit={handleSearchTerm}
            noValidate
            autoComplete='off'
          >
            <Box
              sx={{
                display: 'flex',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                alignItems: 'center',
                flex: 1,
                border: { md: '1px solid #E6E6E6' },
              }}
            >
              <Typography variant='body2' sx={{ p: 2 }}>
                Filtrar por
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: { xs: '12px', md: '0' },
                flexDirection: { xs: 'column', sm: 'row' },
                '.MuiFormControl-root, .MuiAutocomplete-root': {
                  width: { sm: '250px' },
                },
              }}
            >
              <Box sx={{ display: 'flex', gap: { xs: '12px', md: '0' } }}>
                <Autocomplete
                  disablePortal
                  id='faculties'
                  name='faculties'
                  options={facultiesOptions}
                  value={selectedFaculty ? selectedFaculty : null}
                  getOptionLabel={(option) => option}
                  getOptionKey={(option) => option}
                  sx={{ width: '100%' }}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(event, newInputValue) => {
                    handleChangeFaculty(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' {...props} key={option}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='large'
                      label='Facultad'
                      helperText=''
                    />
                  )}
                />

                <Button
                  type='submit'
                  size='large'
                  variant='contained'
                  color='primary'
                  aria-label='Buscar'
                >
                  <SearchIcon />
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ height: 400, width: '100%', mb: 3 }}>
            <DataGrid
              columnVisibilityModel={{ id: false }}
              rows={results}
              columns={columns}
              slotProps={{
                pagination: {
                  labelRowsPerPage: 'Filas por página',
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
              disableColumnMenu
              localeText={localeText}
            />
          </Box>

          <Dialog
            maxWidth='md'
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-user-manage-title'
          >
            <DialogTitle id='alert-dialog-user-manage-title'>
              Usuario {selectedUser?.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Esta seguro que desea inactivarlo
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button
                onClick={handleDialogConfirm}
                autoFocus
                variant='contained'
                disabled={isLoadingToInactivateUser}
              >
                {isLoadingToInactivateUser ? 'Procesando...' : 'Aceptar'}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};
