import { useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { AuthContext } from '../context/AuthContext';

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState('');
  // const [user, setUser] = useState({});
  const [cookie, setCookie] = useState({});

  const resetUserData = () => {
    // setUser({});
    let removeCookie = Cookies.remove('X-SRF-TOKEN');
    sessionStorage.removeItem('AuthenticatedData');
    setCookie(removeCookie);
  };

  const getAccessToken = () => {
    return accessToken;
  };

  const getUser = () => {
    const data = sessionStorage.getItem('AuthenticatedData');
    return data ? JSON.parse(data) : null;
  };

  const getCookie = () => {
    return Cookies.get('X-SRF-TOKEN');
  };

  const isAuthenticated = () => {
    return Cookies.get('X-SRF-TOKEN') && getUser();
  };

  const saveUser = (data) => {
    setAccessToken(data.token);
    // setUser(data);
    sessionStorage.setItem('AuthenticatedData', JSON.stringify(data)); 
    const cookieToken = Cookies.set('X-SRF-TOKEN', data.token, { expires: 1 });
    setCookie(cookieToken);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        getAccessToken,
        saveUser,
        getUser,
        getCookie,
        resetUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
