import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useEffect, useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { localeText } from '../../config/config';
import createAxiosInstance from '../../utils/AxiosConfig';
import ClearIcon from '@mui/icons-material/Clear';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'dayjs/locale/es'; // Importar la localización en español
import { useAuth } from '../../providers/AuthProvider';
import Roles from '../../enums/Roles.enum';

dayjs.locale('es');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Bogota');

export const Reports = () => {
  const auth = useAuth();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appError, setAppError] = useState({
    message: '',
    errors: [],
  });
  const [results, setResults] = useState([]);
  const [response, setResponse] = useState([]);

  useEffect(() => {
    fetchRequests();
    handleSetColumns();
  }, []);

  const handleSetColumns = () => {
    const user = auth.getUser();
    const columnsData = [
      { field: 'id', headerName: 'Id' },
      {
        field: 'Identificación',
        headerName: 'Número de documento',
        minWidth: 230,
        flex: 1,
      },
      { field: 'Nombre', headerName: 'Solicitante', minWidth: 130, flex: 1 },
      {
        field: 'Facultad',
        headerName: 'Facultad',
        minWidth: 130,
        flex: 1,
        renderCell: (params) => {
          const value = `${params.row.Facultad ?? ''}`;
          return <span>{value}</span>;
        },
      },
      {
        field: 'ProgramaAcademico',
        headerName: 'Programa académico',
        minWidth: 130,
        flex: 1,
      },
      {
        field: 'report',
        headerName: 'Reporte',
        description: '',
        sortable: false,
        minWidth: 160,
        flex: 1,
        renderCell: (params) => (
          <IconButton onClick={() => handleButtonClick(params.row)}>
            <DescriptionIcon />
          </IconButton>
        ),
      },
    ];

    const filteredColumns = (user?.role === Roles.FacultySecretary) ? columnsData.filter(column => column.field !== 'Facultad') : columnsData;
    setColumns(filteredColumns);
  }


  const requestsMapper = (data) => {
    const requests = [];
    data.forEach((request) => {
      request.downloads.forEach((download) => {
        requests.push({
          id: requests.length+1,
          idSolicitante: request.id,
          Identificación: request.identification,
          Nombre: request.name,
          Organización: request.organization,
          Nit: request.nit,
          FechaDeDescarga: getFormatDate(download.download_date),
          IdCertificado: download.certificate?.id,
          Sede: download.certificate?.campus_name,
          Facultad: download.certificate?.faculty_name,
          ProgramaAcademico: download.certificate?.title,
          Estudiante: `${download.certificate?.first_name} ${download.certificate?.middle_name} ${download.certificate?.last_name} ${download.certificate?.second_last_name}`,
          FechaCertificado: getFormatDate(download.certificate?.final_date),
        });
      });
    });
    return requests;
  };

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      const user = auth.getUser();
      let url = '';
      if (user?.role === Roles.FacultySecretary) {
        url = `/requests/${user.faculty.id}`;
      } else {
        url = `/requests`;
      }
      const { data } = await createAxiosInstance().get(url);
      setResults(requestsMapper(data));
      setResponse(requestsMapper(data));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppError({
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al obtener los datos',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ?  Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const getFormatDate = (date) => {
    const formatDate = dayjs.utc(date).format('YYYY-MM-DD');
    return formatDate;
  };

  const handleSearchTerm = (event) => {
    event.preventDefault();
    const date = getFormatDate(selectedDate);
    if (selectedDate) {
      const newResults = results.filter((request) =>
        request.FechaDeDescarga.includes(date)
      );
      setResponse(newResults);
      return;
    }
    setResponse(results);
  };

  const downloadCertificate = async (applicant) => {
    setIsLoading(true);
    try {
      const currentDate = dayjs().format('YYYY-MM-DD');
      const request = {
        id: applicant.idSolicitante,
        certificates: [applicant.IdCertificado],
        is_only_download: true,
      };
      const response = await createAxiosInstance().post(`/requests`, request);

      if (response.data) {
        const byteCharacters = atob(response.data.file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${applicant.Identificación}-${applicant.ProgramaAcademico}-${currentDate}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppError({
        message:
        !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al descargar los certificados',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ?  Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const handleButtonClick = (applicant) => {
    downloadCertificate(applicant);
  };

  const handleSelectionModelChange = (selectedOptions) => {
    const selectedObjects = selectedOptions.map(id => response.find(d => d.id === id));
    setSelectedRows(selectedObjects);
  };

  const handleDownload = () => {
    if (selectedRows.length > 0) {
      handleExport(selectedRows);
      setAppError({
        message: '',
        errors: []
      });
    } else {
      setAppError({
        message: 'Selecciona algun registro',
        errors: []
      });
    }
  };

  const handleExport = (selectedRows) => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    // Definir el nombre del archivo y la hoja
    const fileName = `reporte-${currentDate}.xlsx`;
    const sheetName = 'Hoja1';

    // Crear una nueva hoja de trabajo
    const ws = XLSX.utils.json_to_sheet(selectedRows);

    // Crear un nuevo libro de trabajo
    const wb = XLSX.utils.book_new();

    // Agregar la hoja al libro
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // Escribir el libro en formato binario
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Crear un Blob a partir del contenido binario
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Guardar el archivo
    saveAs(blob, fileName);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  return (
    <Box>
      <Typography variant='h4' component='h2'>
        Reportes
      </Typography>
      <Box
        component='form'
        sx={{
          display: 'flex',
          mt: 3,
          mb: 3,
        }}
        onSubmit={handleSearchTerm}
        noValidate
        autoComplete='off'
      >
        <Box position='relative'>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
            <DatePicker
              label='Fecha'
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
            />
            {selectedDate && (
              <IconButton
                aria-label='Resetear fecha'
                onClick={() => setSelectedDate(null)}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '38px',
                  transform: 'translateY(-50%)',
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </LocalizationProvider>
        </Box>
        <IconButton
          type='submit'
          size='large'
          color='primary'
          aria-label='Buscar'
        >
          <SearchIcon />
        </IconButton>
      </Box>
      {appError && appError.message && (
        <Alert severity='error' sx={{ mb: 3, textAlign: 'left' }}>
          {appError.message}
          {appError.errors.length > 0 && (
            <List>
              {appError.errors.map((error, index) => (
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemText primary={error} />
                </ListItem>
              ))}
            </List>
          )}
        </Alert>
      )}
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : response.length > 0 ? (
        <>
          <Box sx={{ height: 400, width: '100%', mb: 3 }}>
            <DataGrid
              columnVisibilityModel={{ id: false }}
              rows={response}
              columns={columns}
              slotProps={{
                pagination: {
                  labelRowsPerPage: 'Filas por página',
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
              checkboxSelection
              disableColumnMenu
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                handleSelectionModelChange(newSelection)
              }
              localeText={localeText}
            />
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Button variant='contained' onClick={() => handleDownload()}>
              Descargar
            </Button>
          </Box>
        </>
      ) : 
        <Alert severity='warning' sx={{ mb: 3, textAlign: 'left' }}>No se encontraron resultados</Alert>
      }
    </Box>
  );
};
