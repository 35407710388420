import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { Footer } from './footer';
import { Header } from './header';
import { IsValidShowResourceByRol } from '../../router/ProtectedRoute';
import Roles from '../../enums/Roles.enum';
import AssignmentIcon from '@mui/icons-material/Assignment'; // Icono para "Trámites"
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'; // Icono para "Oportunidades"


const drawerWidth = 240;

export default function Shell ( { content } )
{

  const { window } = Window;
  const [ mobileOpen, setMobileOpen ] = useState( false );
  const [ isClosing, setIsClosing ] = useState( false );
  const auth = useAuth();

  const handleDrawerClose = () =>
  {
    setIsClosing( true );
    setMobileOpen( false );
  };

  const handleDrawerTransitionEnd = () =>
  {
    setIsClosing( false );
  };

  const handleDrawerToggle = () =>
  {
    if ( !isClosing )
    {
      setMobileOpen( !mobileOpen );
    }
  };

  const logout = () =>
  {
    auth.resetUserData();
    <Navigate to="/login" />
  }

  const convertTextToUpperCaseByWord = ( text ) =>
  {
    return text ? text
      .split( /(?=[A-Z])/ )
      .map( ( word ) => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
      .join( ' ' ) : '';
  };

  const drawer = (
    <div>
      <Toolbar />
      { auth.getUser() && auth.getUser().name &&
        <>
          <Typography variant="h6" component="div" sx={ { p: 2, } }>
            { auth.getUser().name } { auth.getUser()?.lastname }
          </Typography>
          {auth.getUser()?.role !== Roles.Admin &&
            <>
              <Typography variant="body2" sx={ { pl: 2, pr: 2 } }>
                Sede: {auth.getUser().sede.name}
              </Typography>
              <Typography variant="body2" sx={ { pl: 2, pr: 2 } }>
                {auth.getUser().faculty.name}
            </Typography>
            </>
          }
        </>
      }
      <List>
        { IsValidShowResourceByRol( [
          Roles.FacultySecretary] ) &&
          <>
            <ListItem key={ 'usersKey' } disablePadding>
              <NavLink to={ '/usuarios' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary='Usuarios' />
                </ListItemButton>
              </NavLink>
            </ListItem>

            <ListItem key={ 'dashboardKey' } disablePadding>
              <NavLink to={ '/dashboard' } style={ { textDecoration: 'none', width: '100%' } }>
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary='Reporte grafico' />
                </ListItemButton>
              </NavLink>
            </ListItem>
          </>
        }

        { IsValidShowResourceByRol( [
          Roles.Admin] ) &&
          <ListItem key={ 'profileKey' } disablePadding>
            <NavLink to={ '/perfil' } style={ { textDecoration: 'none', width: '100%' } }>
              <ListItemButton>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary='Perfil' />
              </ListItemButton>
            </NavLink>
          </ListItem>
        }

        <ListItem key={ 'reportsKey' } disablePadding>
          <NavLink to={ '/reportes' } style={ { textDecoration: 'none', width: '100%' } }>
            <ListItemButton>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary='Reportes' />
            </ListItemButton>
          </NavLink>
        </ListItem>

        <hr />

        {/* <Typography variant="body2" sx={ { pl: 2, pr: 2 } }>
                App GUIA UNAL
        </Typography> */}

        <ListItem key={'tramitesKey'} disablePadding>
          <NavLink to={'/ListaUnal'} style={{ textDecoration: 'none', width: '100%' }}>
            <ListItemButton>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Trámites" />
            </ListItemButton>
          </NavLink>
        </ListItem>

        <ListItem key={'oportunidadesKey'} disablePadding>
          <NavLink to={'/oportunidadesLista'} style={{ textDecoration: 'none', width: '100%' }}>
            <ListItemButton>
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Oportunidades" />
            </ListItemButton>
          </NavLink>
        </ListItem>

        <ListItem key={ 'logoautKey' } disablePadding>
          <ListItemButton onClick={ () => logout() }>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Cerrar sesión' />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
      }}>
      <Header />
      <Box sx={ { flex: '1 0 auto', display: { tabletPortrait: 'flex' } } }>
        <AppBar
          position="absolute"
          component="div"
          sx={ {
            display: { tabletPortrait: 'none' },
            backgroundColor: '#666',
            zIndex: 3,
            width: { tabletPortrait: `calc(100% - ${ drawerWidth }px)` },
            top: '40px',
            height: '56px',
            ml: { tabletPortrait: `${ drawerWidth }px` },
          } }
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={ handleDrawerToggle }
              sx={ { mr: 2, display: { tabletPortrait: 'none' } } }
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div">
              Responsive drawer
            </Typography> */}
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={ { width: { tabletPortrait: drawerWidth }, flexShrink: { tabletPortrait: 0 } } }
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */ }
          <Drawer
            container={ container }
            variant="temporary"
            open={ mobileOpen }
            onTransitionEnd={ handleDrawerTransitionEnd }
            onClose={ handleDrawerClose }
            ModalProps={ {
              keepMounted: true, // Better open performance on mobile.
            } }
            sx={ {
              display: { xs: 'block', tabletPortrait: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            } }
          >
            { drawer }
          </Drawer>
          <Drawer
            variant="permanent"
            sx={ {
              display: { xs: 'none', tabletPortrait: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, position: 'relative', 'z-index': 1 },
            } }
            open
          >
            { drawer }
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={ { flexGrow: 1, p: 3, width: { tabletPortrait: `calc(100% - ${ drawerWidth }px)` } } }>
          <Toolbar sx={ { display: { tabletPortrait: 'none' } } } />
          { content }
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}
