import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import StarterKit from '@tiptap/starter-kit';
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonBlockquote,
  MenuButtonBulletedList,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonRedo,
  MenuButtonUndo,
  MenuSelectTextAlign,
  MenuButtonHighlightColor,
  MenuButtonTextColor,
  LinkBubbleMenuHandler,
  LinkBubbleMenu,
  MenuButtonEditLink,
} from 'mui-tiptap';
import { Link } from "@tiptap/extension-link";

export default function RichTextFieldControls() {
  return (
    <MenuControlsContainer>
      <MenuSelectHeading />
      <MenuDivider />
      <MenuButtonBold />
      <MenuButtonItalic />
      <MenuButtonBlockquote />
      <MenuButtonBulletedList />
      <MenuButtonRedo />
      <MenuButtonUndo />
      <MenuSelectTextAlign />
      <MenuButtonHighlightColor />
      <MenuButtonTextColor />
      <LinkBubbleMenu />
      <MenuButtonEditLink />
      {/* Add more controls of your choosing here */}
    </MenuControlsContainer>
  );
}

const CustomLinkExtension = Link.extend({
  inclusive: false,
});


export const EditorExtensions = [
  StarterKit,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Highlight.configure({ multicolor: true }),
  TextStyle,
  Color,
  CustomLinkExtension.configure({
    // autolink is generally useful for changing text into links if they
    // appear to be URLs (like someone types in literally "example.com"),
    // though it comes with the caveat that if you then *remove* the link
    // from the text, and then add a space or newline directly after the
    // text, autolink will turn the text back into a link again. Not ideal,
    // but probably still overall worth having autolink enabled, and that's
    // how a lot of other tools behave as well.
    autolink: true,
    linkOnPaste: true,
    openOnClick: false,
  }),
  LinkBubbleMenuHandler,
];
