export const Header = () => {
    return (
        <>
            <header>
                <div className="logo">
                    <a href="https://unal.edu.co">
                        <div className="svg">
                            <img role="img" className="d-print-none img-fluid" alt="Escudo de la Universidad Nacional de Colombia" src="https://unal.edu.co/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/escudoUnal.svg" width="808" height="425" />
                        </div>
                    </a>
                    <div style={{position: 'absolute', top:'1px', width: '60px'}} className="d-none d-print-block">
                        <img className="d-none d-print-block" alt="Escudo de la Universidad Nacional de Colombia" src="/_assets/ca8e1ca9a551c3e3ffc233b30e54ba93/images/escudoUnal_black.png" width="234" height="123" />
                    </div>
                </div>
                <div id="pestania-accesibilidad-mobile" className="d-block d-md-none"></div>
                <div className="seal">
                    <img className="d-print-none" alt="Escudo de la República de Colombia" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Presidential_Seal_of_Colombia_%282%29.svg/200px-Presidential_Seal_of_Colombia_%282%29.svg.png" width="66" height="66" />

                </div>
                {/* <!--menú contextual--> */}
                <div className="firstMenu ">
                    <div className="content-fluid">
                        {/* <!--prueba navbar-->
                        <!--fin de prueba navbar--> */}
                        <nav className="navbar nav navbar-toggler navbar-expand-md navbar-dark">
                            {/* <!-- perfiles --> */}
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className=" collapse navbar-collapse navbar-default">
                                <nav id="profiles">
                                    <ul className="me-auto nav justify-content-end">
                                        <li className="nav-item item_Aspirantes ">
                                            <a href="//aspirantes.unal.edu.co/">Aspirantes</a>
                                        </li>
                                        <li className="nav-item item_Estudiantes">
                                            <a href="//estudiantes.unal.edu.co/">Estudiantes</a>
                                        </li>
                                        <li className="nav-item item_Egresados">
                                            <a href="http://egresados.unal.edu.co">Egresados</a>
                                        </li>
                                        <li className="nav-item item_Docentes">
                                            <a href="//docentes.unal.edu.co/">Docentes</a>
                                        </li>
                                        <li className="nav-item item_Administrativos">
                                            <a href="//administrativos.unal.edu.co/">Administrativos</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            {/* <!-- redes sociales --> */}
                            <ul className="socialLinks d-none d-md-block">
                                <li>
                                    <a href="https://www.facebook.com/UNALOficial" target="_blank" className="facebook" title="Página oficial en Facebook"></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/UNALOficial" target="_blank" className="twitter" title="Cuenta oficial en Twitter"></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCnE6Zj2llVxcvL5I38B0Ceg" target="_blank" className="youtube" title="Canal oficial de Youtube"></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/unaloficial/?hl=es-la" target="_blank" className="instagram" title="Cuenta oficial en Instagram"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {/* <!-- Menú inferior --> */}
                <div id="bs-navbar" className="navigation ">
                    {/* <!-- Título del sitio --> */}
                    <div className="container-fluid myb d-print-inline-block">
                        <div className="site-url row d-print-none" id="subdominio">
                            <span className="oi oi-map-marker"></span>
                            <a href="//unal.edu.co">unal.edu.co</a>
                        </div>
                        {/* <!-- Buscador--> */}
                        <div className="buscador row" id="buscador">

                            <div id="___gcse_0"><div className="gsc-control-searchbox-only gsc-control-searchbox-only-es" dir="ltr"><form className="gsc-search-box gsc-search-box-tools" acceptCharset="utf-8"><table cellSpacing="0" cellPadding="0" role="presentation" className="gsc-search-box"><tbody><tr><td className="gsc-input"><div className="gsc-input-box" id="gsc-iw-id1"><table cellSpacing="0" cellPadding="0" role="presentation" id="gs_id50" className="gstl_50 gsc-input" style={{width: '100%', padding: '0px'}}><tbody><tr><td id="gs_tti50" className="gsib_a">
                                <input autoComplete="off" type="text" size="10" className="gsc-input" name="search" title="buscar" aria-label="buscar" id="gsc-i-id1" style={{width: '100%', padding: '0px', border: 'none', margin: '-0.0625em 0px 0px', height: '1.25em', background: 'url(&quot;https://www.google.com/cse/static/images/1x/es/branding.png&quot;) left center no-repeat rgb(255, 255, 255)', outline: 'none'}} dir="ltr" spellCheck="false" placeholder="Buscar en la Universidad" />
                            </td><td className="gsib_b"><div className="gsst_b" id="gs_st50" dir="ltr"><a className="gsst_a" href="#" title="Borrar contenido del cuadro de búsqueda" role="button" style={{display: 'none'}}><span className="gscb_a" id="gs_cb50" aria-hidden="true">×</span></a></div></td></tr></tbody></table></div></td><td className="gsc-search-button"><button className="gsc-search-button gsc-search-button-v2"><svg width="13" height="13" viewBox="0 0 13 13"><title>buscar</title><path d="m4.8495 7.8226c0.82666 0 1.5262-0.29146 2.0985-0.87438 0.57232-0.58292 0.86378-1.2877 0.87438-2.1144 0.010599-0.82666-0.28086-1.5262-0.87438-2.0985-0.59352-0.57232-1.293-0.86378-2.0985-0.87438-0.8055-0.010599-1.5103 0.28086-2.1144 0.87438-0.60414 0.59352-0.8956 1.293-0.87438 2.0985 0.021197 0.8055 0.31266 1.5103 0.87438 2.1144 0.56172 0.60414 1.2665 0.8956 2.1144 0.87438zm4.4695 0.2115 3.681 3.6819-1.259 1.284-3.6817-3.7 0.0019784-0.69479-0.090043-0.098846c-0.87973 0.76087-1.92 1.1413-3.1207 1.1413-1.3553 0-2.5025-0.46363-3.4417-1.3909s-1.4088-2.0686-1.4088-3.4239c0-1.3553 0.4696-2.4966 1.4088-3.4239 0.9392-0.92727 2.0864-1.3969 3.4417-1.4088 1.3553-0.011889 2.4906 0.45771 3.406 1.4088 0.9154 0.95107 1.379 2.0924 1.3909 3.4239 0 1.2126-0.38043 2.2588-1.1413 3.1385l0.098834 0.090049z"></path></svg></button></td><td className="gsc-clear-button"><div className="gsc-clear-button" title="borrar resultados">&nbsp;</div></td></tr></tbody></table></form></div></div>

                        </div>
                    </div>
                    {/* <!-- Main menu --> */}
                    <nav className="navbar navbar-expand-md navbar-dark menuPrincipal">
                        <div className="container-fluid">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Sedes</a></li>
                                </ul>
                            </div>

                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}
