import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import createAxiosInstance from '../../../utils/AxiosConfig';
import DigitInput from '../digit-input/DigitInput';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('es');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Bogota');

const secondsValue = 60;

const Timer = ({ isActive, setIsActive }) => {
  const [seconds, setSeconds] = useState(secondsValue);

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      setSeconds(secondsValue);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='h4'>{seconds}s</Typography>
    </Box>
  );
};

export const VerificationCode = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  let data = state && state.data;
  let applicant = state && state.applicant;

  useEffect(() => {
    if (!data && !applicant) {
      navigate('/ver-certificados');
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appAlert, setAppAlert] = useState({
    type: 'error',
    message: '',
    errors: []
  });
  const [values, setValues] = useState(Array(6).fill(''));
  const [isActive, setIsActive] = useState(true); // Inicia automáticamente

  const handleBack = () => {
    navigate('/solicitar-datos', {
      state: {
        data,
      },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/buscador-certificados', {
      replace: false,
    });
  };

  const downloadCertificates = async () => {
    setAppAlert({
      message: '',
      errors: [],
    });
    setIsLoading(true);
    try {
      const currentDate = dayjs().format('YYYY-MM-DD');
      const request = {
        id: applicant.id,
        certificates: data.map((c) => c.id),
      };
      const response = await createAxiosInstance().post(`/requests`, request);

      if (response.data && response.data.status) {
        const byteCharacters = atob(response.data.file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${applicant.identification}-${currentDate}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        handleClickOpen();
      } else {
        setAppAlert({
          message: 'Error al descargar los certificados',
          errors: [],
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message:
        !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'Error al descargar los certificados',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ? Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const handleConsult = async () => {
    try {
      const isValid = values.every((v) => v);
      if (isValid) {
        setIsLoading(true);
        const verificationCode = values.toString().replaceAll(',', '');
        const request = {
          id: applicant.id,
          token: verificationCode,
        }
        const { data } = await createAxiosInstance().post(`/requests/validateToken`, request);
        setIsLoading(false);
        if (data.data) {
          downloadCertificates();
        } else {
          setAppAlert({
            type: 'error',
            message: 'Código invalido',
            errors: [],
          });
        }
      } else {
        setAppAlert({
          type: 'warning',
          message: 'Ingresar el código de verificación',
          errors: [],
        });
      }
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'La solicitud fallo',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ? Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const handleResendCode = async () => {
    setIsLoading(true);
    try {
      const request = {
        id: applicant.id
      }
      const {data} = await createAxiosInstance().post(`/requests/resendToken`, request);
      if (data.data) {
        setValues(Array(6).fill(''));
        setIsActive(true);
        setAppAlert({
          type: 'success',
          message: 'Código reenviado correctamente!',
          errors: []
        });
      } else {
        setAppAlert({
          type: 'error',
          message: 'Error el reenviar el código',
          errors: []
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAppAlert({
        type: 'error',
        message:
          !error.response || (error.response.status === 500 || error.statusCode === 500)
            ? 'Error del servidor, intentalo más tarde'
            : 'La Solicitud fallo',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ? Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  }

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Box sx={{ width: { sm: 600 }, pt: 6, pb: 6 }}>
        <Card variant='outlined'>
          <CardContent sx={{ textAlign: 'center' }}>
            {appAlert && appAlert.message && (
              <Alert severity={appAlert.type ?? 'error'} sx={{ mb: 3 }}>
                {appAlert.message}
              </Alert>
            )}
            <Typography
              gutterBottom
              variant='h5'
              component='h2'
              sx={{ mb: 3, fontWeight: 700 }}
            >
              Se ha enviado un número único al correo electrónico registrado
            </Typography>
            <Box textAlign='center'>
              <Typography
                gutterBottom
                variant='h5'
                component='h3'
                sx={{ mb: 3 }}
              >
                Ingrese el código
              </Typography>
              <DigitInput values={values} setValues={setValues}></DigitInput>

              <Box sx={{ mb: 3, mt: 6 }}>
                {isActive ? (
                  <>
                    <Typography
                      gutterBottom
                      variant='body2'
                      sx={{ mb: 3 }}
                    >
                      Puedes reenviar el código en
                    </Typography>
                    <Timer isActive={isActive} setIsActive={setIsActive} />
                  </>
                ) : (
                  <Button variant='text' onClick={() => handleResendCode()}>Reenviar código</Button>
                )}
              </Box>

              <Box
                display='flex'
                justifyContent='space-between'
                gap='8px'
                sx={{ maxWidth: { sm: '60%' }, margin: '0 auto' }}
              >
                <Button variant='contained' onClick={() => handleBack()}>
                  Regresar
                </Button>
                <Button
                  variant='contained'
                  color='success'
                  disabled={isLoading}
                  onClick={() => handleConsult()}
                >
                  {isLoading ? 'Cargando...' : 'Consultar'}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
      >
        <DialogTitle id='alert-dialog-title'>
          Los certificados fueron descargados exitosamente
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
