import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import createAxiosInstance from '../../utils/AxiosConfig';

export const SearchCertificates = () => {
  const navigate = useNavigate();
  const [selector, setSelector] = useState(1);
  const [documentError, setDocumentError] = useState('');
  const [existingDocumentError, setExistingDocumentError] = useState([]);
  const [isValidForm, setIsValidForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [appError, setAppError] = useState({
    message: '',
    errors: [],
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: { multipleDocument: '', singleDocument: '' },
  });
  const [documents, setDocuments] = useState([]);

  const handleOnchange = (value) => {
    setDocuments([]);
    setValue('multipleDocument', '');
    setValue('singleDocument', '');
    setSelector(+value);
  };

  const searchCertificates = async (documents) => {
    setIsLoading(true);
    const request = {
      documents,
    };
    try {

      const { data } = await createAxiosInstance().post(
        `/requests/getAllCertificates`,
        request
      );
      setIsLoading(false);
      if (!data || !data.length) {
        setAppError({
          message: 'No se encontraron resultados',
          errors: [],
        });
        return;
      }

      const documentsNotFound = documents.filter(
        (doc) => !data.some((d) => d.identification_number === doc)
      );
      if (documentsNotFound.length) {
        setAppError({
          message: 'Documentos no encontrados',
          errors: documentsNotFound,
        });
        return;
      }

      navigate(`/ver-certificados/`, {
        replace: false,
        state: { data },
      });
    } catch (error) {
      setIsLoading(false);
      setAppError({
        message: !error.response || (error.response.status === 500 || error.statusCode === 500) ?
        'Error del servidor, intentalo más tarde' :
        'La solicitud fallo',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ? Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const onSubmit = (data) => {
    setAppError({
      message: '',
      errors: [],
    });
    if (selector === 1) {
      setDocuments([data.singleDocument]);
      searchCertificates([data.singleDocument]);
    } else {
      const documentToAdd = data.multipleDocument.split(',');
      const duplicatedDocuments = findDuplicates(documentToAdd);
      if (duplicatedDocuments.length) {
        setAppError({
          message: 'Lista de documentos repetidos',
          errors: duplicatedDocuments,
        });
        setDocumentError('En el input hay documentos repetidos');
        return;
      }

      const documentsFound = documents.filter((doc) =>
        documentToAdd.some((d) => d === doc)
      );
      if (documentsFound.length) {
        setAppError({
          message: 'Lista de documentos existentes',
          errors: documentsFound,
        });
        setDocumentError('Hay documentos que ya estan');
        return;
      }

      const amountDocuments = documents.length + documentToAdd.length;
      if (amountDocuments > 20) {
        setAppError({
          message: 'Solo se permite un máximo de 20 documentos',
          errors: [],
        });
        return;
      }

      setDocumentError('');
      setDocuments((prevDocuments) => [...prevDocuments, ...documentToAdd]);
      reset();
    }
  };

  const findDuplicates = (list) => {
    // Create a map to count occurrences of each element
    const countMap = list.reduce((acc, item) => {
      acc[item] = (acc[item] || 0) + 1;
      return acc;
    }, {});

    // Filter items that appear more than once
    const duplicates = Object.keys(countMap).filter((item) => countMap[item] > 1);

    return duplicates;
  };


  const removeDocument = (index) => {
    const newDocuments = documents.filter((_, i) => i !== index);
    removeExistingDocumentError(index, newDocuments);
    setDocuments(newDocuments);
  };

  const removeExistingDocumentError = (index, documents) => {
    const errors = existingDocumentError.filter((d, i) => i !== index);
    setExistingDocumentError(errors);
    setIsValidForm(!errors.length && areValidDocuments(documents));
  };

  const handleDocumentChange = (index, value) => {
    const newDocuments = documents.map((doc, i) => (i === index ? value : doc));
    setDocuments(newDocuments);
    const isDuplicate = documents.some((d, i) => d === value && i !== index);
    if (isDuplicate) {
      existingDocumentError[index] = 'El documento ya está en la lista';
      setExistingDocumentError(existingDocumentError);
      setIsValidForm(
        !existingDocumentError.length && areValidDocuments(newDocuments)
      );
      return;
    } else {
      removeExistingDocumentError(index, newDocuments);
    }
  };

  const areValidDocuments = (docs) => {
    return docs.every((d) => validateDocument(d));
  };

  const handleSearchCertificates = () => {
    searchCertificates(documents);
  };

  const validateDocument = (value) => {
    const pattern = /^[a-zA-Z0-9]+$/;
    return pattern.test(value) && value.length >= 4 && value.length <= 10;
  };

  const multipleDocumentRules = {
    required: 'El documento es requerido',
    pattern: {
      value: /^([a-zA-Z0-9]{4,10})(,[a-zA-Z0-9]{4,10})*$/,
      message:
        'Los documentos deben tener entre 4 y 10 caracteres y estar separados por comas',
    },
  };

  const singleDocumentRules = {
    required: 'El documento es requerido',
    pattern: {
      value: /^[a-zA-Z0-9]+$/,
      message:
        'El documento tiene que ser alfanúmerico',
    },
    minLength: {
      value: 4,
      message: 'El documento debe tener al menos 4 caracteres',
    },
    maxLength: {
      value: 10,
      message: 'El documento debe tener máximo 10 caracteres',
    },
  };

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Box
        sx={{
          maxWidth: 500,
          flexBasis: 500,
          pt: 6,
          pb: 6,
          textAlign: 'center',
        }}
      >
        <Card variant='outlined'>
          <CardContent>
            {appError && appError.message && (
              <Alert severity='error' sx={{ mb: 3, textAlign: 'left' }}>
                {appError.message}
                {appError.errors.length > 0 && (
                  <List>
                    {appError.errors.map((error, index) => (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <ListItemText primary={error} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Alert>
            )}
            <FormControl>
              <FormLabel
                id='searchCertificates'
                sx={{ fontWeight: '600', mb: 3, fontSize: '18px' }}
              >
                Consulta de verificación de títulos de egresados
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='searchCertificates'
                name='searchCertificates'
                value={selector}
                onChange={(e) => handleOnchange(e.target.value)}
                sx={{ justifyContent: 'center' }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label='Consulta única'
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label='Consulta masiva'
                />
              </RadioGroup>
            </FormControl>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                {selector === 1 ? (
                  <Controller
                    name='singleDocument'
                    control={control}
                    rules={singleDocumentRules}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Documento'
                        variant='outlined'
                        margin='normal'
                        error={!!errors.singleDocument || !!documentError}
                        helperText={
                          errors.singleDocument
                            ? errors.singleDocument.message
                            : documentError
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          setDocumentError('');
                        }}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name='multipleDocument'
                    control={control}
                    rules={multipleDocumentRules}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Documento'
                        variant='outlined'
                        margin='normal'
                        error={!!errors.multipleDocument || !!documentError}
                        helperText={
                          errors.multipleDocument
                            ? errors.multipleDocument.message
                            : documentError
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          setDocumentError('');
                        }}
                      />
                    )}
                  />
                )}

                {selector === 1 ? (
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={isLoading}
                    color='primary'
                    sx={{ mt: 3 }}
                  >
                    {isLoading ? 'Cargando...' : 'Consultar'}
                  </Button>
                ) : documents.length <= 20 ? (
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    sx={{ mt: 3 }}
                  >
                    Agregar
                  </Button>
                ) : null}
              </form>

              {selector === 2 && documents.length > 0 && (
                <>
                  <Typography
                    variant='h6'
                    component='h3'
                    sx={{ mt: 3, mb: 0, fontSize: '16px' }}
                  >
                    Documentos
                  </Typography>
                  <List>
                    {documents.map((doc, index) => (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <Typography
                          variant='body2'
                          sx={{mr: 1}}
                        >
                        {index+1}.
                      </Typography>
                        <Controller
                          name={`documents[${index}]`}
                          control={control}
                          defaultValue={doc}
                          rules={singleDocumentRules}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              fullWidth
                              variant='outlined'
                              margin='normal'
                              value={doc}
                              error={
                                !!fieldState.error ||
                                !!existingDocumentError[index]
                              }
                              helperText={
                                fieldState.error
                                  ? fieldState.error.message
                                  : existingDocumentError[index]
                              }
                              onChange={(e) => {
                                field.onChange(e);
                                handleDocumentChange(index, e.target.value);
                              }}
                            />
                          )}
                        />
                        <IconButton
                          edge='end'
                          aria-label='Eliminar'
                          color='error'
                          onClick={() => removeDocument(index)}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}

              {/*<Box mt={4}>
                <Typography variant='h6'>Documentos Recopilados:</Typography>
                <pre>{JSON.stringify(documents, null, 2)}</pre>
            </Box> */}
            </Box>
            {selector === 2 &&
              (isLoading ? (
                <Button variant='contained' disabled={true} sx={{ mt: 4 }}>
                  Cargando...
                </Button>
              ) : (
                <Button
                  variant='contained'
                  sx={{ mt: 4 }}
                  disabled={!documents.length || !isValidForm}
                  onClick={() => handleSearchCertificates()}
                >
                  Consultar
                </Button>
              ))}
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};
