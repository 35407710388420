import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import createAxiosInstance from '../../../utils/AxiosConfig';

export const RequestData = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let data = state && state.data;

  useEffect(() => {
    if (!data) {
      navigate('/ver-certificados');
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      documentNumber: '',
      entity: '',
      nit: '',
      email: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({
    message: '',
    errors: []
  });

  const onSubmit = async (formData) => {
    setIsLoading(true);

    const request = {
      name: formData.name,
      identification: formData.documentNumber,
      organization: formData.entity,
      email: formData.email,
    };

    if (formData.nit) {
      request.nit = formData.nit;
    }

    try {
      const response = await createAxiosInstance().post(
        `/applicants`,
        request
      );
      navigate(`/verificar-codigo`, {
        replace: false,
        state: {
          data,
          applicant: {
            ...request,
            id: response.data.id,
          },
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setApiError({
        message: !error.response || (error.response.status === 500 || error.statusCode === 500) ?
          'Error del servidor, intentalo más tarde' :
          'La solicitud fallo',
        errors:
          error.response &&
          error.response.data &&
          error.response.data.message
            ? Array.isArray(error.response.data.message) ? error.response.data.message : [error.response.data.message]
            : [],
      });
    }
  };

  const nameRules = {
    required: 'El nombre es requerido',
    pattern: {
      value: /^[A-Za-z\s]+$/i,
      message: 'Solo se permiten letras',
    },
  };

  const entityRules = {
    required: 'La entidad es requerida',
    pattern: {
      value: /^[A-Za-z\s]+$/i,
      message: 'Solo se permiten letras',
    },
  };

  const nitRules = {
    minLength: {
      value: 13,
      message: 'El nit debe tener al menos 13 caracteres',
    },
    maxLength: {
      value: 13,
      message: 'El nit debe tener máximo 13 caracteres',
    },
  }

  const emailRules = {
    required: 'El email es requerido',
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: 'El formato del email no es válido',
    },
  };

  const documentNumberRules = {
    required: 'La cédula es requerido',
    pattern: {
      value: /^[a-zA-Z0-9]+$/,
      message: 'El documento tiene que ser alfanúmerico',
    },
    minLength: {
      value: 4,
      message: 'La cédula debe tener al menos 4 caracteres',
    },
    maxLength: {
      value: 10,
      message: 'La cédula debe tener máximo 10 caracteres',
    },
  };

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Box sx={{ width: { sm: 600 }, pt: 6, pb: 6 }}>
        <Card variant='outlined'>
          <CardContent sx={{ textAlign: 'center' }}>
            {apiError && apiError.message && (
              <Alert severity='error' sx={{ mb: 3, textAlign: 'left' }}>
                {apiError.message}
                { apiError.errors.length > 0 &&
                  <List>
                    {apiError.errors.map((error, index) => (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <ListItemText primary={error} />
                      </ListItem>
                    ))}
                  </List>
                }
              </Alert>
            )}
            <Typography gutterBottom variant='h5' component='h2' sx={{ mb: 3 }}>
              Para descargar la constancia debe ingresar la siguiente información
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name='name'
                control={control}
                rules={nameRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Nombre completo del solicitante*'
                    variant='outlined'
                    margin='normal'
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ''}
                  />
                )}
              />

              <Controller
                name='documentNumber'
                control={control}
                rules={documentNumberRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Cédula del solicitante*'
                    variant='outlined'
                    margin='normal'
                    error={!!errors.documentNumber}
                    helperText={
                      errors.documentNumber ? errors.documentNumber.message : ''
                    }
                  />
                )}
              />

              <Controller
                name='entity'
                control={control}
                rules={entityRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Nombre de la entidad que solicita*'
                    variant='outlined'
                    margin='normal'
                    error={!!errors.entity}
                    helperText={errors.entity ? errors.entity.message : ''}
                  />
                )}
              />

              <Controller
                name='nit'
                control={control}
                rules={nitRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='NIT de la entidad que solicita'
                    variant='outlined'
                    margin='normal'
                    error={!!errors.nit}
                    helperText={errors.nit ? errors.nit.message : ''}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                rules={emailRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Correo electrónico del solicitante*'
                    variant='outlined'
                    margin='normal'
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
              <Button
                type='submit'
                variant='contained'
                disabled={isLoading}
                color='primary'
                sx={{ mt: 3 }}
              >
                {isLoading ? 'Cargando...' : 'Enviar'}
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};
