import React, { useState, useRef } from 'react';
import { Box, TextField } from '@mui/material';

const DigitInput = ({ values, setValues }) => {
//   const [values, setValues] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);

      // Move to next input
      if (index < values.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (/^\d+$/.test(paste)) {
      const newValues = paste.split('').slice(0, values.length);
      setValues(newValues.concat(Array(values.length - newValues.length).fill('')));
      inputRefs.current[newValues.length - 1]?.focus();
    }
    e.preventDefault();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (values[index]) {
        const newValues = [...values];
        newValues[index] = '';
        setValues(newValues);
      } else if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <Box display="flex" gap={1} justifyContent='center' onPaste={handlePaste}>
      {values.map((value, index) => (
        <TextField
          key={index}
          inputRef={(el) => (inputRefs.current[index] = el)}
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
          sx={{ width: 40, }} 
        />
      ))}
    </Box>
  );
};

export default DigitInput;
