import { createTheme } from '@mui/material';

// Create a theme instance.
export const mainTheme = createTheme( {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tabletPortrait: 768,
    },
  },
} );